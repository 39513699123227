.nextStatisticsReleases {
  padding-top: 0.5rem;

  @include media-breakpoint-up(md) {
    padding-top: 3rem;
  }

  .upcoming-releases-head {
    background-color: $ssb-dark-1;
    width: 100vw;
    position: relative;
    left: 50%;
    margin-left: -50vw;

    .upcoming-releases-ingress {
      max-width: 780px;
      margin-top: 1rem;
    }

    @include media-breakpoint-down(md) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  @include media-breakpoint-down(md) {
    .calendar-day {
      flex-direction: column;
      padding-top: 0;
    }
  }

  article,
  .calendar-day {
    display: flex;
    border-bottom: solid 1px $ssb-dark-2;
    padding-top: 1.25rem;

    &:nth-child(1) {
      border-top: 1px solid $ssb-dark-5;
    }

    &:last-child .releaseList {
      border-bottom: 0 none;
    }
  }

  .releaseList {
    list-style: none;
    padding: 0;
    width: 100%;

    .front-page-released-statistic {
      margin-bottom: 1rem;
    }

    .ssb-paragraph:first-letter {
      text-transform: capitalize;
    }

    @include media-breakpoint-up(lg) {
      li:not(.front-page-released-statistic) {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .button-more,
  .text-center {
    margin-top: $spacer * 0.5;
    margin-left: auto;
    margin-right: auto;
  }

  time {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0.25rem 0.4rem;
    background: $ssb-dark-5;
    color: $ssb-white;
    border: 2px solid $ssb-dark-5;
    width: 72px;
    height: 28px;
    margin: 1.25rem 0;
    text-align: center;

    .day {
      font-size: 16px;
      font-family: $font-roboto-condensed-bold;
      line-height: 1.75;
    }

    .month {
      font-size: 16px;
      font-family: $font-roboto-plain;
      line-height: 1.75;
    }
  }

  @include media-breakpoint-up(md) {
    .time-wrapper {
      display: table;
      border: 2px solid $ssb-dark-5;
      width: 97px;
      max-height: 80px;
      margin: 0.5rem 1.25rem 0.1rem 0;
      text-align: center;

      time {
        display: table-cell;
        vertical-align: middle;
        margin: 0.5rem;
        background: $ssb-white;
        color: $ssb-dark-5;
        border: none;

        .day {
          display: block;
          font-size: 40px;
          font-family: $font-roboto-condensed-bold;
          line-height: 1;
        }
      }
    }
  }

  .metadata {
    font-size: 14px;
  }
  .type {
    text-transform: capitalize;
  }
}
