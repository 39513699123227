#map {
  height: 100%;
  .highcharts-container {
  }
  @include media-breakpoint-down(md) {
    height: 80vh;
  }
}
.part-map {
  z-index: 3;
  opacity: 1;
  background-color: white;
  height: 70vh;

  @include media-breakpoint-down(md) {
    height: 80vh;
  }
}
.map-container {
  top: 92px;
  width: 100vw;
  position: fixed;
  height: 100vh;
  z-index: 9999;
  background-color: rgba(39, 66, 71, 0.7);
  .part-map {
    @include media-breakpoint-down(md) {
      padding: 0px;
    }
  }
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}
.overflow-y {
  overflow-y: hidden;
}
.border-map {
  border-color: #c3dcdc;
}
