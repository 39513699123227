.part-variableCardsList {
  margin-top: $spacer * 1;

  .grid-column {
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: $spacer * 2;

    &.grid-4 {
      grid-template-columns: repeat(4, 1fr);

      @include media-breakpoint-down(md) {
        grid-template-columns: none;
      }
    }
  }

  a {
    width: 100%;
  }
}
