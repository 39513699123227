.part-download-link {
  @include make-row();

  > * {
    @include make-col-ready();
    @include make-col(12);

    display: flex;
    justify-content: center;
    margin-bottom: $spacer * 1;

    .download-icon {
      color: $ssb-white;
      margin-right: 5px;
    }

    @include media-breakpoint-down(md) {
      & > .ssb-btn {
        white-space: normal;
        width: 100%;
      }
    }
  }
}



