.part-picture-card {
  padding-top: $spacer * 1.5;
  padding-bottom: $spacer;

  .hide-show-btn {
    padding-top: 40px;

    .col-auto {
      padding-left: 0;
    }
  }

  ul {
    list-style: none;
  }

  // TODO: Remember to fix in ssb component library after bootstrap 5 update
  .ssb-picture-card {
    padding: 0;
  }
}
