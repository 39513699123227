.part-menu-dropdown {
  @include roboto;
  background-color: white;

  .container {
    z-index: 3;
  }

  .menu-content {
    min-height: 92px;
  }

  .title-container {
    opacity: 1;
    min-height: 92px;

    .subtitle {
      font-size: 14px;
    }

    .municipality {
      font-size: 20px;
      line-height: normal;
    }
  }

  .search-container {
    min-height: 92px;
    .change-municipality {
      margin-right: $spacer * 1.5;
    }
  }

  .municipality-mode {
    .title-container {
      opacity: 0;
    }
  }

  &.fixed-top {
    .municipality-mode {
      .title-container {
        opacity: 1;
      }
    }
  }

  button.show-map {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: inherit;

    margin-left: 30px;
    text-decoration: none;
    color: #030303;
    &:focus {
      border: $ssb-green-4 1px solid;
    }
    svg {
      color: $green4;
    }
  }

  .ssb-dropdown {
    width: 100%; // Override design system
    min-width: 100%; // Override design system
    max-width: 100%; // Override design system
  }

  @include media-breakpoint-down(md) {
    .title-container {
      width: 100%;
      justify-content: start;
      padding: 10px 10px 20px;

      .municipality {
        padding-left: 0;
        font-size: 20px;
      }
    }

    .search-container {
      top: 56px;
      border-top: none;
      width: 100%;
      position: absolute;
      background-color: #ffffff;
      padding: 10px 10px 20px;

      .choose-municipality-search {
        width: 100%;
        display: block !important;

        .change-municipality {
          padding-bottom: 10px;
        }
      }
      .show-map {
        display: none;
      }
    }

    .municipality-mode {
      .menu-content,
      .title-container {
        min-height: 56px;
      }

      .search-container {
        top: 0;
      }
    }

    &.fixed-top {
      .title-container {
        padding: 12px 20px 12px;
      }

      .search-container {
        display: none !important;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .title-container {
      .municipality {
        max-width: 70vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
