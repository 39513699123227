.container {
  padding: 0;
  @include media-breakpoint-down(md) {
    .grid-divider:last-child {
      display: none;
    }
  }
}

.full-width {
  padding: 0;
}

.border-top-green {
  position: relative;
  border-top: 4px solid $green3;
  background: #ffffff;
  box-shadow: 0 2px 4px 1px rgba(200, 200, 200, 0.5);
  margin-top: 60px;
  padding: 60px 100px;

  @include media-breakpoint-down(md) {
    padding: 1.25rem 1rem;

    .col,
    .col-12 {
      padding-right: 0;
      padding-left: 0;
    }
  }

  & > h2 {
    font-size: 56px;
    padding-left: 15px;
    border-left: 4px solid $ssb-green-3;
    line-height: 80px;
  }

  h3.layout-title {
    @include roboto-condenced;
    font-weight: bold;
    font-size: 28px;
  }
}

.grey-triangle {
  position: relative;
  background-color: $ssb-dark-5;
  height: 100px;
  width: 100%;
  margin: 0;

  &:after {
    top: 100px;
    position: absolute;
    content: '';
    left: 0px;
    right: 0px;
    border-width: 33vw 49.6vw;
    border-style: solid;
    border-color: $ssb-dark-5 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) $ssb-dark-5;
  }

  @include media-breakpoint-down(md) {
    visibility: hidden;
    display: none;

    &:after {
      visibility: hidden;
    }
  }
}

.grey-triangle + .container,
.grey-triangle + .container-fluid {
  margin-top: -50px;
  position: relative;
}

.container-fluid .part-picture-card {
  margin-top: 60px;
  margin-bottom: -40px;
}

.border-columns {
  border-top: 1px solid $ssb-dark-2;

  @include media-breakpoint-up(md) {
    [data-portal-region='left'] {
      padding-right: 100px;
      border-right: 1px solid $ssb-dark-2;
    }

    [data-portal-region='right'] {
      padding-left: 100px;
    }
  }
}
