.xp-part.rich-text {
  .rich-text-lead-paragraph {
    @include open-sans;
    color: $ssb-dark-6;
    font-size: 20px;
    line-height: 32px;
  } 

  .rich-text-paragraph {
    @include open-sans;
    color: $ssb-dark-6;
    font-size: 16px;
    line-height: 28px;
  }

  &.max-width > * {
    max-width: $max-readable-width;
  }

  * { 
    margin: 0 auto;
    max-width: none;
  }

  span {
    display: block;
  }

  a {
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
  }
}