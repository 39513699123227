.part-infoGraphic {
  h2 {
    font-size: 28px;
    padding-left: 0;
    padding-bottom: 40px;
    border-style: none;
  }
  .footnote {
    list-style-type: none;
    margin-top: 40px;
    padding-left: 0;

    li {
      margin-top: 5px;
    }
  }
  .source-title {
    display: block;
    margin-top: 40px;
    margin-bottom: 0px;
  }
  .sources {
    margin-top: 0px;
  }
  img {
    width: 100%;
  }
}
