.employee {
  .container {
    padding-top: 0!important;
  }

  .employee-head {
    display: flex;
    align-items: center;

    .employee-image img {
      width: 280px;
      height: 350px;
      object-position: top;
      object-fit: cover;

      @include media-breakpoint-down(md) {
        width: 168px;
        height: 210px;
        object-position: top;
        object-fit: cover;
      }
    }

    .employee-title {
      margin-left: 1.33%;
    }
  }

  .employee-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1.875rem 0 .5rem;
    padding-bottom: 2.5rem;
    border-bottom: 1px solid #c3dcdc;

    .details-block {
      padding: 1.25rem 0 0 0;
      border-top: 1px solid #c3dcdc;
      display: flex;

      @include media-breakpoint-up(lg) {
        margin-left: 1rem;

        &:first-child {
          margin-left: 0.625rem;
        }
      }

      svg {
        margin: 0 1rem 0 0;
        stroke-width: 2.5;
      }

      > div {
        font-family: $font-open-sans;
        font-size: 20px;
        line-height: 1.6;

        .position-text {
          hyphens: auto;
          font-weight: 700;
        }
      }
    }

    .ssb-link {
      font-size: 20px;
      padding-bottom: 0;
    }
  }

  .employee-attachments {
    padding: 0 2rem 0 0;

    .instructions {
      margin-top: 2rem;

      h2 {
        font-size: 22px;
      }
    }

    .grid-row {
      display: flex;
      flex-wrap: wrap;
      grid-gap: 1rem;
      padding: 0 4px;

      .grid-column {
        flex: 45%;
        max-width: 45%;

        a {
          .press-picture-thumbnail img {
            width: 100%;
            height: 125.19px;
            object-fit: cover;
          }

          .link-text {
            font-size: 14px;
          }

          &:hover, &:focus {
            outline: none;

            .press-picture-thumbnail {
              outline: $ssb-purple-3 solid 2px;
              outline-offset: 2px;
            }

            .ssb-link {
              background-size: 100% 100%;
              outline: 0;
              text-decoration: none;

              .link-text {
                color: $ssb-white;
              }
            }
          }
        }
      }
    }

    .downloadCv {
      margin-top: 2.5rem;

      button svg {
        margin-right: 0.5rem;
      }
    }
  }

  .mt-4 {
    margin-top: 2rem !important;
  }

  .employee-description {
    margin: 0 0 2.75rem 0;

    > div {
      div {
        display: flex;
        flex-wrap: wrap;
        word-break: break-word;

        p {
          font-size: 20px;
          line-height: 32px;

          a {
            font-size: 20px;
            line-height: 32px;
          }
        }
      }
    }
  }

  .employee-projects {
    h2 {
      margin-bottom: 0;
    }

    > ul {
      list-style: none;
      padding-left: 0;
    }

    > ul li {
      padding: 1.25rem 0;

      a span {
        display: inline-block;
        height: 44px;
      }
    }
  }

  .employee-publications {
    margin-top: 2rem;

    > div {
      margin-top: 1.5rem;
    }
  }
}

@include media-breakpoint-up(md) {
  .employee {
    .desktop-display-none {
      display: none;
    }

    .row-gutter-desktop {
      --bs-gutter-x: 0;
    }
  }
}

@include media-breakpoint-down(md) {
  .employee {
    .employee-head {
      flex-direction: column;
      align-items: flex-start;

      .employee-title {
        margin: 1.5rem 0 .75rem;
      }
    }

    .employee-details {
      flex-direction: column;
      padding-bottom: 0;
      border-bottom: none;
      margin-bottom: 0;
      margin-top: 0;

      .details-block {
        padding: 1.25rem 0;

        .position-feather-icon {
          transform: 'rotate(90deg)';
        }
      }

      .border-if-no-images {
        border-bottom: 1px solid #c3dcdc;
      }
    }

    .desktop-display-none {
      padding-top: 0.875rem;

      > div > button > span > span > svg {
        margin-right: .7rem;
      }
    }

    .mobile-display-none {
      display: none;
    }

    .row .employee-attachments {
      padding: 0;

      .instructions {
        margin-top: 0;

        h2 {
          font-size: 20px;
          line-height: 32px;
        }
      }

      .grid-row {
        grid-gap: .7rem;
        padding: 0 0 0.75rem;

        .grid-column {
          flex: 22%;
          max-width: 22%;

          a {
            .press-picture-thumbnail img {
              height: 100.15px;
            }

            .link-text {
              font-size: 12px;
            }
          }
        }
      }
    }

    .employee-description {
      margin: .5rem 0 3.75rem;
    }

    .employee-description .desktop-display-none > div > button {
      margin: 0;

      svg {
        margin-right: 0.5rem;
      }
    }

    div .row-gutter-mobile {
      --bs-gutter-x: 0;
    }
  }
}
