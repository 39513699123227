.part-local-search {
  background-color: $ssb-dark-2;
  width: 100%;
  max-width: none;

  h2 {
    margin-top: 60px;
    margin-bottom: 30px;
  }
  .search-field {
    margin-bottom: 60px;
  }
}