#ssb-menu {
  #header {
    background: no-repeat scroll center -118px #ececec;
    border-bottom: 1px solid #dfdfdf;
    min-height: 166px;

    &.menu-closed {
      background-color: white;
      border-bottom: 0;
    }
  }

  #statistikker-hovedmeny,
  #main-menu-top {
    a {
      color: #212529;
      outline: none;
      text-decoration: none;
      &:hover {
        color: #212529;
        text-decoration: underline;
      }
    }
  }

  #logo {
    display: block;
    float: left;
    margin: 20px 0 24px;

    img {
      height: 58px;
      image-rendering: optimizeQuality;
    }
  }

  #search {
    float: right;
    clear: right;
    margin-bottom: 10px;

    input[type='submit']:hover,
    input[type='submit']:focus {
      cursor: pointer;
      color: #636363;
      background-color: white;
    }
  }

  #search,
  #search2 {
    label {
      position: absolute;
      left: -9999px;
    }

    input[type='submit'] {
      background-color: #636363;
      background-image: none;
      color: white;
      text-transform: uppercase;
      border: 0;
      vertical-align: middle;
    }
  }

  #sok {
    border: 0;
    box-shadow: 0 0 2px #888 inset;
    //background: url('../images/magnifying-glass-23-21.png') no-repeat scroll 0.5em center white;
    vertical-align: middle;
  }

  #topmenu {
    background-color: #f1efe0;
    height: 2.6em;
    padding-top: 0.2em;
    overflow: visible;
  }
  .innholdsside #topmenu {
    margin-left: 0;
    width: 100%;
  }

  #site-nav-first-item {
    margin-right: 0;
  }

  #site.nav {
    float: left;
    height: 2.6em;
    border-bottom: 1px solid #808080;

    li {
      float: left;
      text-align: center;

      a {
        color: #333;
        display: block;
        font-size: 1.2em;
        font-weight: bold;
        white-space: nowrap;
        padding: 0.6em 1em 0.4em;
        height: 1.15em;
      }

      &.active {
        background-color: #fff;
        border-left: 1px solid #808080;
        border-right: 1px solid #808080;
        border-top: 1px solid #808080;
        margin-bottom: -1px;

        a {
          padding-top: 0.5em;
          height: 1.25em;

          &:hover {
            padding-top: 0.5em;
          }
        }
      }

      &.back-link {
        width: 19em;
        text-align: left;
        overflow: hidden;

        &:hover {
          background-color: #fff;
        }

        span {
          color: #8dbb2b;
          font-size: 1.7em;
          float: left;
          padding: 0.3em 0 0.4em 0.3em;
          height: 0.85em;
        }

        a {
          width: 12em;
          color: #333333;
          padding: 0.6em 0.5em 0.45em;
          height: 1.15em;
          float: left;
        }
      }
    }

    a:hover {
      background-color: #fff;
    }
  }

  .innholdsside #site.nav {
    width: 100%;
  }

  .leteside #site.nav li.back-link {
    display: none;
  }

  #navigation-path {
    margin-bottom: 3em;
    overflow: hidden;

    .nav {
      overflow: hidden;

      li {
        float: left;
        margin-right: 0.5em;
        font-size: 1.3em;
        line-height: 1.3em;

        a {
          line-height: 1.3em;
          text-decoration: underline;

          &:hover {
            color: #a53d7c;
          }

          &:after {
            color: #000;
          }
        }
      }
    }
  }

  #breadcrumbs {
    float: left;
    width: 90em;
  }

  #print-page {
    display: none;
    float: right;
  }
}
