.preface-container + .container {
  margin-top: -50px;
}
.preface-container {
  max-width: 100vw;
  padding: 0;
  margin: 0;

}
.layout-preface {
  position: relative;
  background-color: $ssb-dark-5;
  height: 230px;
  width: 100%;
  margin: 0;
  @include roboto;

  p {
    color: $ssb-white;
    font-size: 22px;
  }
  &:after {
    position: absolute;
    content: "";
    left: 0px;
      right: 0px;
      top: 230px;
      border-width: 33vw 49.6vw;
      border-style: solid;
      border-color: $ssb-dark-5 rgba(0,0,0,0) rgba(0,0,0,0) $ssb-dark-5;

  }

  &.noPrefaceContent {
    height: 100px;

    &:after {
      top: 100px;
    }
  }

  @include media-breakpoint-down(lg) {
    .preface-inner {
      flex: 0 0 75%;
      max-width: 75%;

    }

  }

  @include media-breakpoint-down(md) {
    font-size: 16px;
    min-height: 230px;
    height: auto;

    &:after {
      visibility: hidden;
    }
  }

  @media print {
    height: 80px;
  }
}
