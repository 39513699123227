.part-statbank-link {

  .title-wrapper {
    margin-bottom: 30px;

  }

  .statbank-link {
    margin-top: $spacer * 1.5;
  }

  ul {
    list-style: none;
    padding-left: 0;

    li {
      margin-bottom: 20px;

    }
  }
}
