.xp-part.video-embed {
  width: 100%;
  height: 0;
  padding-bottom: calc(100% / (16 / 9));
  position: relative;

  h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }

  // Need a placeholder that can fill space in edit mode if video does not show. 
  // After ID is added in config a page refresh is needed to see the video in edit mode
  // Seems CS removes web-components when updating the page in edit mode
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    height: 80%;
    width: 90%;
    background-color: $ssb-dark-5;
  }
}