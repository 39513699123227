.key-figures {
  h3 {
    @include roboto-condenced;
    font-weight: bold;
    font-size: 28px !important;
  }

  & .divider:not(:last-child) {
    @include media-breakpoint-down (md) {
      border-bottom: 1px solid #cccccc;
      padding-bottom: 50px;
      padding-left: 0;
      padding-right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.macro-keyfigure{
	.green-box {
		margin-bottom: 15px;
	}
}