.name-search {
  margin-top: 2.5rem;

  .name-search-content {
    border: 1px solid $ssb-green-5;
    border-top: 4px solid $ssb-green-3;
  }

  .name-search-input {
    padding: 3.75rem;
    background: white;
  }

  .name-search-result {
    padding: 3.75rem;
    border-top: 1px solid $ssb-green-5;
    background-color: $ssb-dark-1;

    .close-button {
      margin: auto;
      margin-top: 2rem;
    }
  }

  .front-page-results {
    padding: 3.1rem 0 3.75rem 0;

    @include media-breakpoint-up(lg) {
      & > * {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
      }
    }
  }

  .result-highlight {
    font-size: 1.25rem;
    font-family: $font-roboto-bold;

    .details {
      font-weight: 700;
    }
  }

  .interesting-facts {
    list-style: none;
  }

  .name-search-name {
    text-transform: capitalize;
  }

  .name-search-link {
    .accordion-body {
      padding: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    .name-search-input {
      height: 25rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .name-search-about-link {
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .name-search-input {
      padding: 3.75rem 1.25rem 2.5rem;

      .result-button {
        width: 100%;
        justify-content: center;
      }
    }

    .name-search-result {
      padding: 2.5rem 1.25rem 2.5rem;
    }
  }
}
