.article-archive {
  h1 {
    margin-top: $spacer * 3;
    margin-bottom: 20px;
  }

  .preamble {
    margin-bottom: $spacer * 2;

    p {
      max-width: 100%;
    }
  }

  .list-of-articles-container {
    .chevron-icons {
      margin-right: $spacer * 0.5;
    }

    .list-of-articles-title {
      margin-top: $spacer * 3.5;
      margin-bottom: $spacer * 1.5;
    }

    hr {
      margin-bottom: $spacer * 1;
    }

    .articles-container {
      margin-bottom: $spacer * 1.5;

      & > * {
        @include media-breakpoint-down(md) {
          padding: 0;
        }
      }

      .article-container {
        margin-top: $spacer * 0.25;
        margin-bottom: $spacer * 1.75;

        @include media-breakpoint-down(md) {
          padding: 0;
        }
      }
    }
  }

  .free-text {
    margin-top: 2rem;
  }

  .issn-text {
    margin-bottom: $spacer * 2;
  }

  ol {
    list-style-type: none;

    @include media-breakpoint-up(lg) {
      margin-left: $spacer * 1.5;
    }
  }
}
