.part-related-external-links {
  @include make-container;
  margin-top: 40px;
  padding: 0;

  & > div {
    @include make-row;

    & > * {
      @include make-col-ready();
      @include make-col(4);
      margin-bottom: $spacer * 1;

      @include media-breakpoint-down(md) {
        @include make-col(12);
      }
    }
  }
}