
/* -- Headings -- */

h1,h2 {
  @include roboto-condenced;
  font-weight: bold;
  margin-top: 0;
}
h3,h4,h5,h6 {
  @include roboto;
  font-weight: bold;
  color: $ssb-dark-6;
  margin-top: 0;
}
h1 {
  font-size: 3.5rem;
  line-height: 5rem;
}
h2 {
  font-size: 1.75rem;
  line-height: 2.5rem;

  &.statbank-title{
	font-size: 28px;
	line-height: 40px;
  }
}
h3 {
  font-size: 1.25rem;
  line-height: 2rem;
}
h4 {
  font-size: 1rem;
  line-height: 1.75rem;
}
h5 {
  font-size: 1.125rem;
  line-height: 1.7;
}
h6 {
  font-size: 1rem;
  line-height: 1.7;
}

@media #{$mobile} {
  h1 {
    font-size: 2.75rem;
    line-height: 3.5rem;
  }
  h2 {
    font-size: 1.75rem;
    line-height: 2.25rem;

	&.statbank-title{
		font-size: 28px;
		line-height: 36px;
	}
  }
}

h1 {
  hyphens: auto;
}

/* -- End headings -- */


/* -- Text -- */

body {
  @include open-sans;
  color: $ssb-dark-6;
}

p {
  @include open-sans;
  color: $ssb-dark-6;
  font-size: 1rem;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.7;
  //max-width: $max-readable-width; <-- this is screwing with center alignment of text, TODO: maybe find a fix? (not critical)

  &.small-text {
    font-size: 0.875rem;
  }

  &.negative {
    color: $ssb-white;
  }
}

ul {
  li, ol {
    line-height: 1.7rem;
  }
}

/* -- End text -- */


/* -- Links -- */

$default-font-size: 1rem;
$profiled-font-size: 1.125rem;
$header-font-size: 1.25rem;
$icon-padding: 8px;

p > a, p > strong > a, ul > li > a, ol > li > a, table a, .rich-text span a {
  @include open-sans;
  align-items: center;
  background-image: linear-gradient(120deg, $ssb-green-4 0%, $ssb-green-4 100%);
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 0;
  border-bottom: 1px solid $ssb-green-4;
  color: $ssb-green-4;
  cursor: pointer;
  display: inline;
  font-size: $default-font-size;
  line-height: 1.7rem;
  margin-top: -3px;
  margin-bottom: -2px;
  padding: 0 1px 2px 1px;
  position: relative;
  text-decoration: none;
  transition: background-size .2s ease-in, color .1s;

  .icon-wrapper {
    display: flex;
    margin-right: 4px;
  }

  &.header {
    @include roboto;
    border-bottom-width: 2px;
    font-size: $header-font-size;
    font-weight: bold;

    .link-text, span {
      color: $ssb-dark-6;
    }
  }

  &:hover, &:focus {
    background-size: 100% 100%;
    color: $ssb-white;
    outline: 0;
    text-decoration: none;

    .link-text, span {
      color: $ssb-white;
    }
  }

  &.negative {
    background-image: linear-gradient(120deg, $ssb-green-2 0%, $ssb-green-2 100%);
    border-bottom: 1px solid $ssb-green-2;
    color: $ssb-green-2;

    .link-text, span {
      color: $ssb-green-2;
    }

    &.header {
      border-bottom-width: 2px;

      .link-text, span {
        color: $ssb-white;
      }
    }

    &.with-icon {
      padding: 2px 2px 0;
      border-bottom: none;
      display: inline-flex;
      align-items: flex-start;

      i {
        font-size: 1.25rem;
      }

      .link-text, span {
        color: $ssb-white;
      }

      &:hover, &:focus {
        color: $ssb-dark-5;

        .link-text, span {
          color: $ssb-dark-5;
        }
      }

    }

    &:hover, &:focus {
      color: $ssb-dark-5;
      outline: 0;

      .link-text, span {
        color: $ssb-dark-5;
      }
    }
  }

  &.profiled {
    border-bottom-width: 2px;
    font-size: $profiled-font-size;
    font-weight: bold;
  }

  &.with-icon {
    padding: 2px 2px 0;
    border-bottom: none;
    display: inline-flex;
    align-items: flex-start;

    i {
      font-size: 1rem;
    }

    .link-text, span {
      color: $ssb-dark-6;
    }

    &:hover, &:focus {
      color: $ssb-white;

      .link-text, span {
        color: $ssb-white;
      }
    }
  }

  .link-text, span {
    color: $ssb-green-4;
    transition: color .1s;

    &:hover, &:focus {
      color: $ssb-white;
      outline: 0;
    }
  }
}

p > strong > a {
  font-weight: inherit;
}

/* -- End links -- */

/* -- Blockquote -- */
blockquote > p {
  @include open-sans;
  background-image: linear-gradient(120deg, $ssb-green-1 0%, $ssb-green-1 100%);
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 50%;
  display: inline;
  font-size: 1.25rem;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.6;
}
/* -- End Blockquote -- */

/* -- Table -- */

table {
  border-collapse: collapse;

  td, th {
    border: 1px solid black;
    padding: 5px;
  }

}
/* -- End Table -- */

/* -- Placeholder -- */
.edit-placeholder {
  border: 3px solid $ssb-dark-4;
  padding: 16px;
  margin: 16px 0
}

/* -- End Placeholder -- */

/* -- Opacity Divider,Fix in komponent library later -- */
.ssb-divider {
  opacity: 1;
}

