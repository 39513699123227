.publication-archive {
  .publication-archive-head {
    background-color: $ssb-dark-1;
    width: 100vw;
    position: relative;
    left: 50%;
    margin-left: -50vw;

    .publication-archive-ingress {
      max-width: 780px;
      margin-top: 1rem;
    }

    @include media-breakpoint-down(md) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .publication-archive-body {
    & > * {
      max-width: 580px;
    }
  }
  .defineContentPhrase {
    font-family: Roboto, sans-serif !important;
    font-stretch: normal;
    font-weight: 700;
    margin: 0;
    font-size: 1rem;
    padding-bottom: 1rem;
  }

  .button-more {
    margin-left: auto;
    margin-right: auto;
  }
}
