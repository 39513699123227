.article {
  .title-ingress-wrapper {
    display: block;
    margin-bottom: $spacer * 1;
    margin-top: 3rem;

    @include media-breakpoint-down(md) {
      padding: 0;
    }

    h1 {
      margin-bottom: 1.25rem;
      padding: 0;
    }

    .ingress {
      font-size: 1.25rem;
      padding-left: 0;
    }

    .introTitle {
      @include roboto;
      font-size: 1.25rem;
      line-height: 1.6;
      margin-bottom: 0.25rem;
    }
  }

  .snr-dates-wrapper {
    margin-bottom: 0.5rem;

    p {
      margin-bottom: 0;
      margin-right: 1.25rem;
    }

    @include media-breakpoint-down(md) {
      p {
        margin-bottom: $spacer * 0.5;
      }
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: row;
    }
  }

  .author-wrapper {
    margin-bottom: $spacer * 3;

    p {
      margin-bottom: 0;
    }
  }

  .part-fact-box {
    padding: 0;
    margin: 1rem 0;
  }

  .article-body {
    & > * + section.part-divider,
    section.part-divider + * {
      margin-top: $spacer * 3;
    }

    ul,
    ol {
      padding-left: 1rem;
    }

    & > * {
      margin-bottom: $spacer * 1.5;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: $spacer * 0.5;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: $spacer * 3;
    }

    @include media-breakpoint-up(lg) {
      margin-right: $spacer * 3;
    }
  }

  blockquote {
    padding-left: 2.5rem !important;

    @include media-breakpoint-down(md) {
      padding-left: 0 !important;
    }
  }

  .associated-statistics,
  .associated-article-archives {
    margin-top: $spacer * 2.5;
    padding: 0;

    & > * {
      div {
        margin-top: $spacer * 0.5;

        & > * {
          margin-bottom: $spacer * 1;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      border-left: 1px solid $ssb-dark-2;
      padding-left: $spacer;

      .mt-lg-0 {
        margin-top: 0;
      }
    }
  }

  .article-isbn {
    @include media-breakpoint-down(md) {
      margin-bottom: $spacer * 3;
    }
  }
}
