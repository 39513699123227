.part-table {
  .download-table-container {
    display: flex;
    margin-top: $spacer * 2;
    margin-bottom: $spacer * 2;

    label {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      justify-content: flex-end;
    }

    @include media-breakpoint-down(md) {
      margin-top: $spacer * 2;
      margin-bottom: $spacer * 2;
    }

    .ssb-dropdown {
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }

  .source {
    margin-bottom: $spacer;
    margin-top: 1.5rem;

    .source-title {
      color: $ssb-dark-6;
    }

    .source-link {
      padding-bottom: $spacer * 0.5;
    }

    & a {
      margin-top: 10px;
    }
  }

  @include media-breakpoint-down(md) {
    width: 95%;
    margin: 0;

    .source > div {
      padding: 0;
    }
  }

  .ssb-link {
    margin-top: 10px;
  }
}

.highcharts-data-table .ssb-table {
  min-width: 0;
}

table.statistics tbody tr td:not(:first-child) {
  text-align: right;
}

table.statistics thead tr:last-child th {
  text-align: right;
}

table.statistics thead th {
  text-align: right !important;
}

.highcharts-data-table.ssb-table-wrapper .ssb-table thead tr:first-child th:first-child {
  text-align: left !important;
}

table.statistics {
  thead {
    th[scope='colspan'] {
      text-align: right;
    }
  }
}

table.statistics thead th[scope='col'] {
  text-align: right !important;
}

table.statistics {
  width: 100%;

  thead,
  tbody {
    th.sum,
    td.sum {
      font-weight: bold;
    }

    td.preliminary:before {
      content: '*';
    }
  }

  tbody {
    th,
    td {
      height: 36px;
    }

    th {
      &:first-child {
        text-align: left;
      }

      &.colspan {
        text-align: left;
        font-weight: bold;
      }

      &:last-child {
        border-right: none;
      }

      &.title {
        font-weight: bold;
        font-size: 1.1rem;
      }
    }
  }

  thead,
  tbody tr.head {
    th {
      &[colspan],
      &[colgroup] {
        text-align: center !important;
      }
    }
  }
}

ul.statbankkilde {
  padding-left: 1rem;
  font-size: 1.3rem;
  list-style-type: none;

  li {
    margin-bottom: 0.4rem;
  }
}

sup {
  margin: 0 0.3rem;
}
